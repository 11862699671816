<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark font-poppins">Theme</span>
      </h3>
    </div>
    <!--end::Header-->
    <div class="row card-body pt-0 pb-3">
      <div class="col-md-12">
        <div class="row">
          <div class="form-group col-md-4">
            <label>Theme</label>
            <b-form-select v-model="theme" :options="filteredThemes"></b-form-select>

            
            <span class="form-text text-muted"
              >Select theme to load configuration</span
            >
            </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <!-- <component :is="theme"></component> -->
          <TemplateSettings v-if="theme" :template="theme" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>

import { GET_TEMPLATES, GET_CURRENT_TEMPLATE } from "@/core/services/store/profile.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Template1 from "@/view/pages/templates/Template1";
import TemplateSettings from "@/view/pages/business/components/settings/TemplateSettings";
import { mapGetters } from "vuex";

export default {
  name: "Setting",
  components: {
    Template1,
    TemplateSettings
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserPersonalInfo"]),
    filteredThemes() {
      return this.themes.filter(theme => theme.text !== null);
    }
  },
  data() {
    return {
      themes: [
        { text: null, value: "Select your theme" }
      ],
      theme: null
    };
  },
  mounted() {
    this.theme = parseInt(this.currentUserPersonalInfo.template_id);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Settings" }
    ]);

    this.$store.dispatch(GET_TEMPLATES).then((result) => {
        result.forEach((v) => {
          this.themes.push({
            value: v.id,
            text: v.template_name,
          });
        });
      });
  }
};
</script>
